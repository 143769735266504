import React, { useEffect, useState } from "react";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import APIService from "../utils/APIService";
import { urlEndpoints } from "../utils/APIHelper";
import { getToken } from "../Helper";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import Toggle from "../components/common/Toggle";
import Input from "../components/common/Input";
import SimpleDropdown from "../components/common/SimpleDropdown";

const LicenceInformation = () => {
  const access = getToken();
  const { t } = useTranslation();
  const [thingGroup, setThingGroup] = useState([]);
  const [tenantName, setTenantName] = useState("");
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [loderText, setLoderText] = useState("Loading");
  const [tenantInfo, setTenantInfo] = useState({
    entitlement: [], // Initialize entitlement as an empty array
    id: "",
    name: "",
    cloudBucket: "",
    tenantID: "",
  });

  useEffect(() => {
    getThingGroups();
  }, []);

  const getThingGroups = () => {
    setLoaderVisibility(true);
    APIService(urlEndpoints.getThingGroups, {
      Authorization: `Bearer ${access}`,
    })
      .then((response) => {
        setThingGroup(response.data);
        setLoaderVisibility(false);
      })
      .catch((error) => {
        setLoaderVisibility(false);
        handleError(error);
      });
  };

  const handleDropdownClick = (selectedOption) => {
    setTenantName(selectedOption);
  };

  const getTenantInfo = () => {
    setLoaderVisibility(true);
    APIService(
      urlEndpoints.fetchLicenceInformation,
      { Authorization: `Bearer ${access}` },
      { params: tenantName }
    )
      .then((response) => {
        const responseJSON = JSON.stringify(response.data, null, 2); // Pretty format for debugging
        setTenantInfo(response.data); // Ensure entire tenantInfo is updated
        setLoaderVisibility(false);
      })
      .catch((error) => {
        setLoaderVisibility(false);
        handleError(error);
      });
  };

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    const updatedEntitlement = [...tenantInfo.entitlement];
    updatedEntitlement[index][name] = type === "checkbox" ? checked : value;
    setTenantInfo({ ...tenantInfo, entitlement: updatedEntitlement });
  };

  const handleSave = () => {
    APIService(
      urlEndpoints.updateLicenceInformation,
      { Authorization: `Bearer ${access}` },
      { ...tenantInfo }
    ).then(() => {
        toast.success(t('message.LICENCE_INFORMATION_UPDATED_SUCESSFULLY'), {
          autoClose: 2000,
          theme: "dark",
        });
        getTenantInfo();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleError = (error) => {
    if (error.response) {
      toast.error(error.response.data.message, {
        autoClose: 2000,
        theme: "dark",
      });
    } else {
      toast.error(t('message.SERVER_ERROR'), {
        autoClose: 2000,
        theme: "dark",
      });
    }
  };

  return (
    <LayoutWrapper
      loderText={loderText}
      title={t('labels.licenses_information')}
      visibility={loaderVisibility}
    >
      <section className="inner-wrapper table-top">
        <div className="w-100">
          <div className="cus-row">
            <label className="col-form-label">{t('labels.TENANT_NAME')}</label>
            <SimpleDropdown
              id="thingGroupDropdown"
              name="thingGroup"
              className="btn-group dpw-50"
              options={thingGroup}
              label={tenantName || "Select a Tenant"}
              deopdownClick={handleDropdownClick}
              scrollClassName="dropdown-scroll"
            />
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-secondary" onClick={getTenantInfo}>
              {t('labels.SEARCH')}
            </button>
          </div>
          <div className="table-scroll">
            <table className="table table-dark table-striped table-hover table-font">
              <thead>
                <tr>
                  <th>Description</th>
                  <th style={{ width: "20%", textAlign: "center" }}>Status</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {tenantInfo.entitlement.map((element, index) => (
                  <tr key={index}>
                    <td className="col-form-label">{element.description}</td>
                    <td style={{ width: "20%", textAlign: "center" }}>
                      <Toggle
                        divClassname="form-check form-switch"
                        switchClassname="form-check-input"
                        labelClassname="form-check-label switch-label-right"
                        label={element.active ? t('labels.enabled') : t('labels.disabled')}
                        id={`active-${index}`}
                        name="active"
                        value={element.active}
                        checked={element.active}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                    <td>
                      <Input
                        parentDivClassName="ethernet-input"
                        labelClassName="floating-label"
                        type="text"
                        autoComplete="off"
                        value={element.value || ""}
                        className="inputText"
                        name="value"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-secondary" onClick={handleSave}>
              {t('labels.UPDATE')}
            </button>
          </div>
        </div>
      </section>
    </LayoutWrapper>
  );
};

export default LicenceInformation;
