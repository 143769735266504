import React from "react";
import { useRoutes } from "react-router-dom";
import Login from "./container/Login";
import AuthGaurad from './components/common/AuthGaurad';
import TenantManagement from "./container/TenantManagement";
import TenantInformation from "./container/TenantInformation";
import OnBoardingOTP from "./container/OnBoardingOTP";
import CommandControl from "./container/CommandControl";
import DeviceMovement from "./container/DeviceMovement";
import LicenceInformation from "./container/LicenceInformation";

function Routes() {
  const routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/tenantManagement", element: <AuthGaurad><TenantManagement /></AuthGaurad> },
    { path: "/tenantInformation", element: <AuthGaurad><TenantInformation /></AuthGaurad> },
    { path: "/licenceInformation", element: <AuthGaurad><LicenceInformation /></AuthGaurad> },
    { path: "/commandControl", element: <AuthGaurad><CommandControl /></AuthGaurad> },
    { path: "/deviceMovement", element: <AuthGaurad><DeviceMovement /></AuthGaurad> },
    { path: "/generateOTP", element: <AuthGaurad><OnBoardingOTP /></AuthGaurad> }
  ]);
  return routes;
}

export default Routes;
