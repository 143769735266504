import React, { useEffect, useState } from "react";
import "../assets/css/rdmportal.css";
import APIService from "../utils/APIService";
import { urlEndpoints } from "../utils/APIHelper";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import { toast, ToastContainer } from "react-toastify";
import Input from "../components/common/Input";
import { useTranslation } from 'react-i18next';
import { getToken } from "../Helper";
const TenantManagement = () => {
    const access = getToken();
    const [tenantName, setTenantName] = useState("");
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loderText, setLoderText] = useState("Loading");
    const { t, i18n } = useTranslation();
    const createTenant = () => {
        if (!tenantName) {
            toast.error('Tenant Name is required', { autoClose: 2000, theme: 'dark' });
            return;
        }

        setLoaderVisibility(true);
        APIService(
            urlEndpoints.createTenant,
            {Authorization: `Bearer ${access}`},
            { tenantName: tenantName}
        )
            .then((response) => {
                setLoaderVisibility(false);
                toast.success(t('message.TENANT_CREATED_SUCESSFULLY'), {
                    autoClose: 2000,
                    theme: "dark",
                })
            })
            .catch((error) => {
                    setLoaderVisibility(false);
                    if (error.response && error.response.status === 409) {
                        toast.error("Tenant with given name already exists.", {
                            autoClose: 2000,
                            theme: "dark",
                        });
                    } 
                    else if (error.response) {
                        toast.error(error.response.data.message, {
                            autoClose: 2000,
                            theme: "dark",
                        });
                    } else {
                        toast.error(t("message.SERVER_ERROR"), {
                            autoClose: 2000,
                            theme: "dark",
                        });
                    }
                  });
    };
    return (<LayoutWrapper loderText={loderText} title={t('labels.TENANT_MANAGEMENT')} visibility={loaderVisibility}>
        <section class="inner-wrapper table-top">
            <div class="w-100">
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.TENANT_NAME')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50"
                            type="text"
                            id="tenantName"
                            name="tenantName"
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                            onChange={(e) => setTenantName(e.target.value)}
                        />
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn btn-secondary" type="button" onClick={createTenant} >{t('labels.CREATE_TENANT')}</button>
                    </div>
            </div>
        </section>
    </LayoutWrapper>);
}
export default TenantManagement;   