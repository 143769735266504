import React from "react";
import rightarrow from '../../assets/images/right-arrow.svg';
const Toggle = ({ label, id, name, checked, onChange, value, disabled, divClassname, switchClassname, labelClassname,rightLabel,rightLabelClassname}) => {
  return (
    <>
        <div className={divClassname}>
         { label  && (<label className={labelClassname}>
            {label}
          </label> )}
          <input
            className={switchClassname}
            type="checkbox"
            name={name}
            onChange={onChange}
            value={value}
            checked={checked}
            id={id}
            disabled={disabled}
          />
            { rightLabel  && (<label className={rightLabelClassname}>
            {rightLabel}
          </label> )}
        </div>
    </>
  );
};
export default Toggle;
