import React, { useEffect, useState } from "react";
import APIService from "../utils/APIService";
import LayoutWrapper from '../components/ui/LayoutWrapper';
import Input from "../components/common/Input";
import SimpleDropdown from "../components/common/SimpleDropdown";
import { urlEndpoints } from "../utils/APIHelper";
import { toast } from "react-toastify";
import { getToken } from "../Helper";
import { useTranslation } from 'react-i18next';
import "../assets/css/rdmportal.css";
const DeviceMovement = () => {
    const access = getToken();
    const [loaderVisibility, setLoaderVisibility] = useState(false);
    const [loderText, setLoderText] = useState("Loading");
    const [tenantName, setTenantName] = useState("");
    const { t, i18n } = useTranslation();
    const [thingGroup, setThingGroup] = useState([]);
    const [thingNames, setThingNames] = useState([]);
    const [thingName, setThingName] = useState(""); // State for Thing Name
    useEffect(() => {
        getThingGroups();
        getThingsFromInventoryGroup();
    }, []);

    const getThingsFromInventoryGroup = () => {
        setLoaderVisibility(true)
        APIService(
            urlEndpoints.fetchThings,
            { Authorization: `Bearer ${access}` },
            { params: 'Inventory' }
        )
        .then((response) => {
                console.log(" response.data >> " + response.data);
                setThingNames(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const getThingGroups = () => {
        setLoaderVisibility(true)
        APIService(urlEndpoints.getThingGroups, {
            Authorization: `Bearer ${access}`,
        })
            .then((response) => {
                console.log(" response.data >> " + response.data);
                setThingGroup(response.data);
                setLoaderVisibility(false);
            })
            .catch((error) => {
                setLoaderVisibility(false)
                if (error.response) {
                    toast.error(error.response.data.message, {
                        autoClose: 2000,
                        theme: "dark",
                    });
                } else {
                    toast.error(t('message.SERVER_ERROR'), {
                        autoClose: 2000,
                        theme: "dark",
                    });
                }
            });
    };

    const handleDropdownClick = (selectedOption) => {
        console.log("Selected Thing Group:", selectedOption);
        setTenantName(selectedOption); // Update state with selected value
    };

    const handleThingSelectedDropdownClick = (selectedOption) => {
        console.log("Selected Thing :", selectedOption);
        setThingName(selectedOption); // Update state with selected value
    };

     // Handle API call for device movement
  const handleDeviceMovement = () => {
    if (!thingName) {
      toast.error("Thing Name is required", {
        autoClose: 2000,
        theme: "dark",
      });
      return;
    }

    if (!tenantName) {
        toast.error("Thing Group is required", {
          autoClose: 2000,
          theme: "dark",
        });
        return;
    }

    setLoaderVisibility(true);
    const deviceMovement = {
      thingName,
      newThingGroupName: tenantName,
      currentThingGroupName: "Inventory",
    };
    console.log("deviceMovement >> " + JSON.stringify(deviceMovement, null, 2));
    APIService(urlEndpoints.deviceMovement, 
        {Authorization: `Bearer ${access}`},
        deviceMovement
    ).then((response) => {
        console.log("Device Movement Response: ", response.data);
        toast.success("Device movement successful!", {
          autoClose: 2000,
          theme: "dark",
        });
        setLoaderVisibility(false);
      })
      .catch((error) => {
        setLoaderVisibility(false);
        if (error.response && error.response.status === 404) {
            toast.error("Thing not found. Please check the provided names.", {
                autoClose: 2000,
                theme: "dark",
            });
        } 
        else if (error.response) {
            toast.error(error.response.data.message, {
                autoClose: 2000,
                theme: "dark",
            });
        } else {
            toast.error(t("message.SERVER_ERROR"), {
                autoClose: 2000,
                theme: "dark",
            });
        }
      });
  };

    return (<LayoutWrapper loderText={loderText} title={t('labels.DEVICE_MOVEMENT')} visibility={loaderVisibility}>
        <section class="inner-wrapper table-top">
            <div class="w-100">
                <form>
                <div class="cus-row">
                        <label class="col-form-label">{t('labels.SOURCE_THING_GROUP')}</label>
                        <Input
                            parentDivClassName="etherner-input  dpw-50 "
                            type="text"
                            id="srcThingGroup"
                            name="srcThingGroup"
                            value={"Inventory"}
                            autoComplete="off"
                            required={true}
                            className="sa-input"
                            maxLength={30}
                            readOnly={true}
                        />
                    </div>
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.THING_NAME')}</label>
                        <SimpleDropdown
                                id="thingName"
                                name="thingName"
                                className="btn-group dpw-50"
                                options={thingNames} 
                                label={thingName || "Select a Thing"}
                                deopdownClick={handleThingSelectedDropdownClick}
                                scrollClassName="dropdown-scroll"
                            />
                    </div>
                    
                    <div class="cus-row">
                        <label class="col-form-label">{t('labels.TARGET_THING_GROUP')}</label>
                        <SimpleDropdown
                                id="trgThingGroup"
                                name="trgThingGroup"
                                className="btn-group dpw-50"
                                options={thingGroup.filter(group => group !== "Inventory")} // Dynamically fetched options
                                label={tenantName || "Select a Target Tenant"}
                                deopdownClick={handleDropdownClick}
                                scrollClassName="dropdown-scroll"
                            />
                    </div>
                    <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleDeviceMovement}
            >
              {t("labels.SAVE")}
            </button>
          </div>
                </form>
            </div>
        </section>
    </LayoutWrapper>);
}
export default DeviceMovement;   