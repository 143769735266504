import React,{useState,useEffect} from "react";
import backarrow from "../../assets/images/back-arrow.svg";
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import { tokenParse, getToken } from "../../Helper";
import fetchLanguage from "../../utils/LanguageService";
import { useTranslation } from 'react-i18next';
const Header = ({ ...props }) => {
  const tokenparse = tokenParse();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const access = getToken();
  const { t, i18n } = useTranslation();
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [language, setLanguage] = useState('en');
  let onlyProfile = false;
  if (sessionStorage.getItem("onlyProfile")) {
    onlyProfile = true;
  }
  return (
    <nav class="navbar-cus">
      {location.pathname != '/settings' ? location.pathname == '/settings/myprofile' || location.pathname == '/settings/devicesetup'
        || location.pathname == '/generateOTP'
        || location.pathname == '/tenantManagement'
        || location.pathname == '/licenceInformation'
        || location.pathname == '/commandControl'
        || location.pathname == '/deviceMovement'
        || location.pathname == '/tenantInformation'
        || onlyProfile ? (<button class="btn-back"></button>) : (
        <button class="btn-back" onClick={props.backUrl}>
          <img src={backarrow} class="img-fluid btn-back-arrow" />
          {t('labels.BACK')}</button>) : (<button class="btn-back"></button>)}
      <h3 className=
        {onlyProfile === "true" && location.pathname.includes("myprofile") ? "title-center-device" : "title-center"}>{props.title}</h3>
      {/*        <button class="btn btn-link"><img src={WindowControls} /></button> */}
    </nav>
  );
};

export default Header;



