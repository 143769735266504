import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
const SimpleDropdown = ({
  id,
  name,
  options,
  label,
  deopdownClick,
  required,
  value,
  error,
  touched,
  className,
  scrollClassName,
  disabled
}) => (
  <>
    <input type="hidden" name={name} value={value} id={id} />
    <Dropdown className={className + (error && touched ? ' error-dropdown' : ' no-error-dropdown')}>
      <Dropdown.Toggle className="btn btn-drop-down dropdown-toggle" disabled={disabled}>
        {label} <span class="caret"></span>
      </Dropdown.Toggle>
      <Dropdown.Menu className={scrollClassName}>
        {options.map((option) => (
          <Dropdown.Item key={option} onClick={() => deopdownClick(option)}>{option}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
      {error && touched && (
        <span className='error'>{error}</span>
      )}
    </Dropdown>
  </>
);

export default SimpleDropdown;
